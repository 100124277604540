/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Styling for controls in FileList component
*/

.file-list {
    font-size: 12px;
    background-color: white;
    display: block;
    margin: 0 auto;
    border-radius: 3px 0px;
    padding: 0px 0px 0px 20px;
}
