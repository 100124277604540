/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Styling for controls in ToolBar component
*/

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.tool-bar {
    height: 40px;
    font-size: 12px;
    background-color: #555;
    display: block;
    margin: 0 auto;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0px 0px 0px 0px;
    overflow: hidden;
}

    .tool-bar button {
        width: 40px;
        height: 40px;
        background-color: #555;
        display: inline-block;
        color: white;
        vertical-align: middle;
        text-align: center;
        transition: all 0.1s ease;
    }

        .tool-bar button:disabled {
            background-color: #999999;
            color: #4caf50;
        }

        .tool-bar button:hover:not([disabled]) {
            background-color: #000;
            color: #4caf50;
        }

.iconButton {
    font-size: 20px;
    line-height: 40px;
}

.active {
    background-color: #4caf50;
}

.menuSection {
    display: inline-block;
    color: #ffffff;
    margin: 0px 10px 0px 10px;
}

.separator {
}

label.pageField, input.pageField {
    width: 30px;
    color: #000000;
    text-align: center;
    vertical-align: middle;
    line-height: 22px;
    background-color: #ffffff;
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 3px;
    border: 1px solid #000000;
}

    label.pageField:disabled, input.pageField:disabled {
        background-color: #999999;
        color: #4caf50;
    }

label.pagePosCount {
    min-width: 50px !important;
    line-height: 40px;
    margin: 0px 5px 0px 5px;
}

.goToPageButton {
    width: 50px;
    height: 24px;
    line-height: 24px;
    background-color: dodgerblue;
    border: 1px solid #000000;
    margin: 0px;
}

    .goToPageButton:hover:not([disabled]) {
        background-color: deepskyblue;
    }

input.searchText {
    width: 50px !important;
    text-align: left;
}

input.matchCase {
    line-height: 40px;
    margin: 0px 5px 0px 5px;
}

label.matchCaseLabel {
    line-height: 40px;
    margin: 0px 10px 0px 0px;
}

label.matchPosCount {
    min-width: 30px !important;
    line-height: 40px;
    margin: 0px 5px 0px 5px;
}

input.searchText:disabled, input.matchCase:disabled, label.matchCaseLabel:disabled, label.matchCount:disabled {
    background-color: #999999;
    color: #4caf50;
}

.searchOption {
    margin-left: 5px;
}

.closeButton {
    float: right;
    margin: 0px;
    border-radius: 0px 4px 0px 0px;
}

    .closeButton a {
        color: #ffffff;
        background-color: rgb(200, 10, 10);
    }

        .closeButton a:hover {
            background-color: rgb(255, 10, 10);
        }
