/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Styling for controls in Search component
*/

.searchBaseContainer {
    font-size: 14px;
    width: 100vw;
    background-color: white;
    display: flex;
}

.searchControlContainer {
    height: 90vh;
    background-color: lightgray;
    width: 30vw;
}

.grpSearchMainControl {
    display: block;
    height: 50vh !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 2vh !important;
}

.grpSearchAdvancedOptions {
    display: block;
    overflow-y: auto;
    padding: 0 !important;
    margin: 0 !important;
}

.searchAdvancedContainer {
    height: 35vh !important;
}

Form {
    margin: 1vw 1vh !important;
}

    Form.Field {
        padding: 0 !important;
        margin: 0 !important;
    }

.searchFormControlLabel {
    width: 5vw !important;
    font-weight: normal !important;
}

.searchFormControlDropDown {
    width: 16vw !important;
}

.searchFormControlDate {
    width: 12vw !important;
}

.searchFormControlAdvancedCheckBox {
    margin-left: 6vw !important;
}

.searchFormControlButton {
    margin-left: 6vw !important;
    width: 10vw !important;
}
.advancedSearchFormControlLabel {
    font-weight: normal;
}
.advancedSearchFormControlTextBox {
    width: 21vw !important;
}

.searchResultContainer {
    background-color: transparent;
    width: 100%;
    height: 83vh;
    overflow-y: auto;
}

table.searchResultTable {
    position: relative;
    width: 100%;
    font-size: 12px;
    font-weight: normal;
}

    table.searchResultTable thead tr {
        height: 40px;
    }

        table.searchResultTable thead tr th {
            vertical-align: middle;
            padding: 0px 5px !important;
            margin: 0 !important;
            background-color: #666666;
            position: sticky;
            top: -10px; /* Don't forget this, required for the stickiness */
            z-index: 1;
        }

    table.searchResultTable tbody tr {
        height: 24px;
    }

        table.searchResultTable tbody tr td {
            padding: 2px 5px !important;
            margin: 0 !important;
        }

.search-tool-bar {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 5px;
    width: 76vw;
    font-size: 12px;
    background-color: #555;
    display: block;
    margin: 0 auto;
    border-radius: 3px;
    padding: 0px 0px 0px 20px;
}

    .search-tool-bar button {
        width: 40px;
        height: 40px;
        background-color: #555;
        display: inline-block;
        color: white;
        vertical-align: middle;
        text-align: center;
        transition: all 0.1s ease;
    }

        .search-tool-bar button:disabled {
            background-color: #999999;
            color: #4caf50;
        }

        .search-tool-bar button:hover:not([disabled]) {
            background-color: #000;
            color: #4caf50;
        }

#pageInfo.menuSection {
    position: relative;
}

.iconButton {
    font-size: 20px;
    line-height: 40px;
}

.active {
    background-color: #4caf50;
}

.menuSection {
    display: inline-block;
    color: #ffffff;
    margin: 0px 5px 0px 5px;
}

.separator {
    border-right: 1px solid #999999;
    padding-right: 5px;
}

.dropDownField {
    width: 70px;
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.resultCount {

}

.busyMsg {
    font-weight: bold;
}
