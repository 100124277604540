/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Styling for controls in Visualizer component
*/

#visualizerWrapper {
    width: 95%;
    height: 525px;
    margin: 0 auto;
    background: #f7f7f7;
    padding: 0px;
}

#closeButton {
    width: 45px;
    color: #ffffff;
    background-color: rgb(200, 10, 10);
    display: block;
    text-align: center;
    font-size: 30px;
    margin: 0;
    text-align: center;
    position: absolute;
    border-radius: 10px;
    top: 10px;
    left: 96%;
}

    #closeButton:hover {
        background-color: rgb(255, 10, 10);
    }

.visualizerContainer {
    width: 99%;
    height: 90%;
    margin: 0 auto;
    background: #f7f7f7;
    padding: 0px;
    overflow: hidden;
}

#fileContainer {
    float: left;
    transform-origin: top left;
}

.visualizer {
}
