/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Styling for App component and other common styling used in Historical Data application
*/

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}
/*
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
*/

/* Reset Select */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    box-shadow: none;
    border: 1 !important;
    background: #ffffff;
    background-image: none;
}
    /* Remove IE arrow */
    select::-ms-expand {
        display: none;
    }
/* Custom Select */
.select {
    position: relative;
    display: flex;
    background: none;
    overflow: auto;
    border-radius: .25em;
}

select {
    flex: 1;
    padding: 0 .5em;
    color: #222222;
    cursor: pointer;
}

/* Arrow */
.select::after {
    content: '\25BC';
    position: absolute;
    top: 1;
    right: 0;
    padding: 0 5px;
    background: none;
    color: #000000;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}
/* Transition */
.select:hover::after {
    color: #000000;
}

th, td {
    padding: 1px;
}
/*
.nav-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    color: white;
}

li {
    padding: 5px;
}

ul.nav-links li:hover {
    border-radius: 10px;
    background-color: #0099ff;
}

ul.nav-links-admin li:hover {
    background-color: #404040;
}

ul.nav-links-admin li:hover {
    background-color: #606060;
}
*/

.appBase {
    height: 100vh;
    background-color: dodgerblue;
    color: white;
}

.appTitle {
    font-size: 20px;
    color: white;
    padding-left: 10px;
}

.loginButtonDiv, .logoutButtonDiv, .authenticatingDiv, .loadingDiv {
    margin-top: 40vh;
    text-align: center;
}

#loginLink, #logoutLink {
    width: 150px;
    margin: 0 auto;
}

.unauthorizedMsg {
    color: white;
}

.navbar {
    height: 10vh !important;
    background-color: dodgerblue;
}

.navbarItem {
    display: inline;
}

.navbarTitle {
    float: left;
    margin-left: 10px;
    font-size: 20px;
    color: white;
}

.navbarMenu {
    width: 40%;
    float: right;
    margin-left: 20px;
}

.navbarAffiliate {
    float: right;
    width: 18%;
    margin-left: 20px;
}

.navbarUserInfo {
    float: right;
    width: 15%;
    margin-left: 20px;
}
